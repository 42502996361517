import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import Pencil from '../../../images/pencil.svg';
import {
  AlumniWidgetBuilderCompaniesModal,
  TextButton,
  WidgetBuilderHeadingToggle
} from 'components';

const { mediumDarkGray, sapphireD20 } = color;

const EditIcon = styled(Pencil)`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  margin-right: 0.75rem;
`;

const ModalButtonRow = styled.div`
  width: 100%;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: flex-end;
  margin: -1.9rem 0 2rem 0;
`;

const ModalButton = styled(TextButton)`
  width: auto;
`;

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid ${mediumDarkGray};
  border-radius: 3px;
`;

const Label = styled.label`
  display: block;
`;

const AlumniWidgetBuilderCompanies = ({ configurations, selectedPrograms, setConfigurations }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localCompaniesHeading, setLocalCompaniesHeading] = useState(
    configurations.carouselHeadingText
  );

  useEffect(() => {
    const debouncedUpdateParentState = setTimeout(() => {
      setConfigurations({ ...configurations, carouselHeadingText: localCompaniesHeading });
    }, 400);
    return () => clearTimeout(debouncedUpdateParentState);
  }, [localCompaniesHeading]);

  return (
    <Fragment>
      <WidgetBuilderHeadingToggle
        configurations={configurations}
        setConfigurations={setConfigurations}
        headingText="Companies"
        configurationKey="hasCarousel"
      />

      <ModalButtonRow isHidden={configurations.isBatch}>
        <ModalButton
          color={sapphireD20}
          disabled={configurations.isBatch}
          onClick={() => setIsModalOpen(true)}
        >
          <EditIcon data-cy="edit-companies-button" />
          Edit Companies
        </ModalButton>
      </ModalButtonRow>

      <Label>
        Edit Companies Heading Text
        <TextArea
          id="companies-header-text"
          rows="4"
          cols="50"
          data-cy="companies-heading-textarea"
          value={localCompaniesHeading}
          onChange={event => {
            setLocalCompaniesHeading(event.target.value);
          }}
        />
      </Label>

      {isModalOpen && (
        <AlumniWidgetBuilderCompaniesModal
          isOpen={isModalOpen}
          configurations={configurations}
          selectedPrograms={selectedPrograms}
          setConfigurations={setConfigurations}
          toggleIsModalOpen={() => setIsModalOpen(!isModalOpen)}
        />
      )}
    </Fragment>
  );
};

AlumniWidgetBuilderCompanies.propTypes = {
  configurations: PropTypes.object.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderCompanies;
