import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WidgetBuilderSelect } from 'components';
import { states } from './states';

const Heading = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const AlumniWidgetBuilderRegion = ({ configurations, setConfigurations }) => {
  return (
    <Fragment>
      <Heading>Geography</Heading>
      <WidgetBuilderSelect
        configurations={configurations}
        id="geography"
        label="Region"
        optionsList={[{ label: 'National', value: '0' }, ...states]}
        selectionLabel="selectedGeography"
        setConfigurations={setConfigurations}
        onChange={({ target: { value } }) => {
          const newGeographyLevel = value === '0' ? 'nation' : 'state';
          setConfigurations({
            ...configurations,
            geographyLevel: newGeographyLevel
          });
        }}
      />
    </Fragment>
  );
};

AlumniWidgetBuilderRegion.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderRegion;
