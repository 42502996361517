import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';

const { mediumDarkGray } = color;

const Heading = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid ${mediumDarkGray};
  border-radius: 3px;
`;

const Label = styled.label`
  display: block;
`;

const AlumniWidgetBuilderOverviewHeading = ({ configurations, setConfigurations }) => {
  const [overviewHeading, setOverviewHeading] = useState(configurations.overviewHeadingText);

  useEffect(() => {
    const debouncedUpdateParentState = setTimeout(() => {
      setConfigurations({ ...configurations, overviewHeadingText: overviewHeading });
    }, 400);
    return () => clearTimeout(debouncedUpdateParentState);
  }, [overviewHeading]);

  return (
    <Fragment>
      <Heading>Overview</Heading>
      <Label>
        Edit overview heading text
        <TextArea
          id="overview-heading-text"
          rows="4"
          cols="50"
          data-cy="overview-heading-textarea"
          value={overviewHeading}
          onChange={event => {
            setOverviewHeading(event.target.value);
          }}
        />
      </Label>
    </Fragment>
  );
};

AlumniWidgetBuilderOverviewHeading.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderOverviewHeading;
