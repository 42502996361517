import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchAlumniPathwaysData } from '../../../services';
import { CheckboxModal } from 'components';

const AlumniWidgetBuilderCompaniesModal = ({
  configurations,
  isOpen,
  selectedPrograms,
  setConfigurations,
  toggleIsModalOpen
}) => {
  const [selectedOptions, setSelectedOptions] = useState(configurations.selectedCompanies);
  const [companiesData, setCompaniesData] = useState();
  const [isError, setIsError] = useState(false);

  const fetchCompanyData = async () => {
    const {
      clientId,
      graduationTimeframeEnd,
      graduationTimeframeStart,
      hasLowConfidenceMatches,
      isOnlyAfterGraduation,
      selectedCampus,
      selectedDegreeLevel
    } = configurations;

    const filter = {
      hasLowConfidenceMatches,
      inst_campus: selectedCampus,
      inst_program: selectedPrograms,
      inst_degree_level: selectedDegreeLevel,
      isOnlyAfterGraduation,
      graduationTimeframeStart,
      graduationTimeframeEnd
    };

    try {
      const { companies } = await fetchAlumniPathwaysData({
        dataType: 'companies',
        filter,
        subdomain: clientId
      });
      setCompaniesData(companies);

      if (configurations.selectedCompanies && configurations.selectedCompanies.length === 0) {
        setSelectedOptions(companies.slice(0, 6));
      }
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!companiesData) {
      fetchCompanyData();
    }
  }, [companiesData]);

  return (
    <CheckboxModal
      directionsText="Select up to 6 companies to display in your widget. At least one company must be selected."
      headingText="Select Companies"
      isError={isError}
      isOpen={isOpen}
      options={companiesData}
      selectionLimit={6}
      selectedOptions={selectedOptions}
      setSelectedOptions={selectedOptions => {
        setConfigurations({
          ...configurations,
          selectedCompanies: selectedOptions
        });
      }}
      toggleIsModalOpen={toggleIsModalOpen}
    />
  );
};

AlumniWidgetBuilderCompaniesModal.propTypes = {
  configurations: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  setConfigurations: PropTypes.func.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderCompaniesModal;
