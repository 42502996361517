import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AlumniWidgetBuilderCampus,
  AlumniWidgetBuilderCompanies,
  AlumniWidgetBuilderDegreeLevel,
  AlumniWidgetBuilderHeading,
  AlumniWidgetBuilderOccupationsAndSkills,
  AlumniWidgetBuilderOverviewHeading,
  AlumniWidgetBuilderPrograms,
  AlumniWidgetBuilderOverviewData,
  AlumniWidgetBuilderRegion,
  HorizontalRule,
  WidgetBuilderExportTypeToggle,
  WidgetBuilderHeadingToggle,
  WidgetBuilderTimeFrame
} from 'components';

const Sidebar = styled.div`
  min-width: 35rem;
  height: 75rem;
  padding: 0 2rem 2rem 1rem;
  border-right: 2px solid #f0f1f3;
  overflow-y: auto;
`;

const Header = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 700;
`;

const AlumniWidgetBuilderSidebar = props => {
  const { configurations, setConfigurations } = props;
  const { hasSubsequentAttainment, primaryColor } = configurations;
  return (
    <Sidebar>
      <Header>Select Alumni Data</Header>
      <WidgetBuilderExportTypeToggle {...props} />
      <HorizontalRule />
      <AlumniWidgetBuilderCampus
        configurations={configurations}
        setConfigurations={setConfigurations}
        {...props}
      />
      <HorizontalRule />
      <AlumniWidgetBuilderPrograms {...props} />
      <HorizontalRule />
      <AlumniWidgetBuilderDegreeLevel
        configurations={configurations}
        setConfigurations={setConfigurations}
        {...props}
      />
      <HorizontalRule />
      <WidgetBuilderTimeFrame
        configurations={configurations}
        heading="Graduation Timeframe"
        setConfigurations={setConfigurations}
      />
      <HorizontalRule />
      <WidgetBuilderHeadingToggle
        configurations={configurations}
        setConfigurations={setConfigurations}
        headingText="Jobs After Graduation"
        configurationKey="isOnlyAfterGraduation"
        description="Include only alumni with jobs starting after graduation"
      />
      <HorizontalRule />
      <WidgetBuilderHeadingToggle
        configurations={configurations}
        setConfigurations={setConfigurations}
        headingText="Low Confidence Matches"
        configurationKey="hasLowConfidenceMatches"
        description="Include low confidence matches"
      />
      <HorizontalRule />
      <AlumniWidgetBuilderHeading
        configurations={configurations}
        setConfigurations={setConfigurations}
      />
      <HorizontalRule />
      <AlumniWidgetBuilderOverviewHeading
        configurations={configurations}
        setConfigurations={setConfigurations}
      />
      <HorizontalRule />
      <AlumniWidgetBuilderOverviewData
        configurations={configurations}
        setConfigurations={setConfigurations}
      />
      <HorizontalRule />
      <AlumniWidgetBuilderRegion
        configurations={configurations} // here
        setConfigurations={setConfigurations}
      />
      <HorizontalRule />
      <AlumniWidgetBuilderCompanies {...props} />
      <HorizontalRule />
      <AlumniWidgetBuilderOccupationsAndSkills {...props} />
      <HorizontalRule />
    </Sidebar>
  );
};

AlumniWidgetBuilderSidebar.propTypes = {
  configurations: PropTypes.shape({
    primaryColor: PropTypes.string
  }).isRequired,
  currentSite: PropTypes.string,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderSidebar;
