import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WidgetBuilderProgramModal } from 'components';
import { fetchAlumniPathwaysData } from '../../../services';

export const fetchProgramsForWidget = async ({
  currentSite,
  setPrograms,
  setFilteredPrograms,
  setIsProgramsLoading
}) => {
  setIsProgramsLoading(true);
  const newProgramsData = await fetchAlumniPathwaysData({
    dataType: 'programs',
    subdomain: currentSite
  });
  const formattedProgramsData = newProgramsData.map(({ id, title }) => ({
    name: title,
    code: id,
    slug: title.toLowerCase()
  }));
  setPrograms(formattedProgramsData);
  setFilteredPrograms(formattedProgramsData);
  setIsProgramsLoading(false);
};

const AlumniWidgetBuilderProgramModal = ({
  currentSite,
  nation,
  programsData,
  selectedPrograms,
  toggleIsModalOpen,
  ...props
}) => {
  const [selectedLocalProgramsByCode, setSelectedLocalProgramsByCode] = useState({});
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  useEffect(() => {
    if (isProgramsLoading && programs.length === 0 && !!programsData) {
      const formattedProgramsData = programsData.map(({ id, title }) => ({
        name: title,
        code: id,
        slug: title.toLowerCase()
      }));
      setPrograms(formattedProgramsData);
      setFilteredPrograms(formattedProgramsData);
      setIsProgramsLoading(false);
    }
  }, [programs, programsData]);

  useEffect(() => {
    if (props.isOpen && !isProgramsLoading && programs.length === 0 && programsData.length === 0) {
      setIsProgramsLoading(true);
      fetchProgramsForWidget({
        currentSite,
        setPrograms,
        setFilteredPrograms,
        setIsProgramsLoading
      });
    }
  }, [isProgramsLoading, programs, programsData, props.isOpen]);

  useEffect(() => {
    const selectedProgramsObject = {};
    for (let index = 0; index < selectedPrograms.length; index++) {
      const { code } = selectedPrograms[index];
      selectedProgramsObject[code] = true;
    }
    setSelectedLocalProgramsByCode(selectedProgramsObject);
    setFilteredPrograms(programs);
  }, [selectedPrograms, programs, setSelectedLocalProgramsByCode, toggleIsModalOpen]);

  return (
    <WidgetBuilderProgramModal
      programs={programs}
      currentSite={currentSite}
      nation={nation}
      toggleIsModalOpen={toggleIsModalOpen}
      filteredPrograms={filteredPrograms}
      setFilteredPrograms={setFilteredPrograms}
      isProgramsLoading={isProgramsLoading}
      setIsProgramsLoading={setIsProgramsLoading}
      selectedLocalProgramsByCode={selectedLocalProgramsByCode}
      setSelectedLocalProgramsByCode={setSelectedLocalProgramsByCode}
      {...props}
    />
  );
};

AlumniWidgetBuilderProgramModal.propTypes = {
  currentSite: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired,
  programsData: PropTypes.array,
  selectedPrograms: PropTypes.array.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderProgramModal;
