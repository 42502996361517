import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';
import { Button, Loading, Modal } from 'components';

const { darkerGray, gray, red } = color;

export const Card = styled.div`
  background: white;
  padding: 4rem 2rem 2rem;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

const Heading = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const Error = styled.p`
  width: 100%;
  text-align: center;
  color: ${red};
`;
const Options = styled.div`
  display: flex;
`;

const OptionColumn = styled.ul`
  width: 50%;
`;
const Label = styled.label`
  margin: 0 0 0 0.5rem;
`;

const ButtonRow = styled.div`
  width: 18rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const CheckboxModal = ({
  directionsText,
  headingText,
  isOpen,
  isError,
  options,
  selectedOptions,
  selectionLimit = 20,
  setSelectedOptions,
  toggleIsModalOpen
}) => {
  const [localOptions, setLocalOptions] = useState();
  const [localSelectedOptions, setLocalSelectedOptions] = useState(selectedOptions);

  useEffect(() => {
    if (!!options) {
      const newLocalOptions =
        selectedOptions.length > 0
          ? [
              ...selectedOptions,
              ...options
                .filter(option => !selectedOptions.map(({ id }) => id).includes(option.id))
                .slice(0, options.length - selectedOptions.length)
            ]
          : options;
      setLocalOptions(newLocalOptions);
    }
  }, [options]);

  useEffect(() => {
    if (localSelectedOptions && localSelectedOptions.length === 0 && selectedOptions.length > 0) {
      setLocalSelectedOptions(selectedOptions);
    }
  }, [selectedOptions]);

  const Checkbox = ({ detail, id, title }) => {
    const isSelected = localSelectedOptions.filter(option => option.id === id).length !== 0;
    return (
      <div>
        <input
          type="checkbox"
          id={id}
          value={id}
          data-cy={`checkbox-modal-option-${id}`}
          name={title}
          disabled={!isSelected && localSelectedOptions.length >= selectionLimit}
          checked={isSelected}
          onChange={() => {
            if (isSelected) {
              setLocalSelectedOptions(localSelectedOptions.filter(option => option.id !== id));
            } else {
              setLocalSelectedOptions([...localSelectedOptions, { detail, id, title }]);
            }
          }}
        />
        <Label htmlFor={id}>{`${title}${!!detail ? ` (${detail})` : ''}`}</Label>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} close={toggleIsModalOpen} height="90%">
      <Card>
        <Heading>{headingText}</Heading>
        <p>{directionsText}</p>
        {isError && <Error role="alert">An error has occurred. Please try again later.</Error>}
        {!localOptions && !isError && <Loading />}
        {!!localOptions && (
          <Options>
            <OptionColumn>
              {localOptions.slice(0, 10).map(option => (
                <Checkbox key={`checkbox-modal-${option.id}`} {...option} />
              ))}
            </OptionColumn>
            <OptionColumn>
              {localOptions.slice(11, 20).map(option => (
                <Checkbox key={`checkbox-modal-${option.id}`} {...option} />
              ))}
            </OptionColumn>
          </Options>
        )}
        <ButtonRow>
          <Button
            onClick={() => {
              setSelectedOptions(localSelectedOptions);
              toggleIsModalOpen();
            }}
            data-cy="checkbox-modal-save-button"
            disabled={localSelectedOptions.length === 0}
          >
            Save
          </Button>
          <Button
            color={darkerGray}
            background={gray}
            onClick={toggleIsModalOpen}
            data-cy="checkbox-modal-cancel-button"
          >
            Cancel
          </Button>
        </ButtonRow>
      </Card>
    </Modal>
  );
};

CheckboxModal.propTypes = {
  directionsText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, id: PropTypes.string })),
  selectionLimit: PropTypes.number,
  setSelectedOptions: PropTypes.func.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default CheckboxModal;
