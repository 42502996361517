import { getCognitoToken } from '../';

const createAlumniPathwaysFilter = ({
  graduationTimeframeStart,
  graduationTimeframeEnd,
  hasLowConfidenceMatches,
  isOnlyAfterGraduation,
  ...rest
}) => {
  const match_confidence = hasLowConfidenceMatches ? ['low', 'high'] : ['high'];

  const currentYear = String(new Date().getFullYear());
  const gradyearStart =
    graduationTimeframeStart === 'current' ? currentYear : graduationTimeframeStart;
  const gradyearEnd = graduationTimeframeEnd === 'current' ? currentYear : graduationTimeframeEnd;

  const newFilter = {
    ...rest,
    gradyear: { start: gradyearStart, end: gradyearEnd },

    match_confidence
  };

  if (isOnlyAfterGraduation) {
    newFilter.inst_is_graduate = true;
  }

  return newFilter;
};

export const fetchAlumniPathwaysData = async ({ filter, subdomain, dataType = 'all' }) => {
  const response = await fetch(`/api/alumni-pathways/${dataType}?subdomain=${subdomain}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${await getCognitoToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data: {
        filter: !!filter ? createAlumniPathwaysFilter(filter) : {}
      }
    })
  });
  const {
    data: { attributes }
  } = await response.json();
  return attributes;
};
