export const states = [
  {
    value: '1',
    label: 'Alabama'
  },
  {
    value: '2',
    label: 'Alaska'
  },
  {
    value: '4',
    label: 'Arizona'
  },
  {
    value: '5',
    label: 'Arkansas'
  },
  {
    value: '6',
    label: 'California'
  },
  {
    value: '8',
    label: 'Colorado'
  },
  {
    value: '9',
    label: 'Connecticut'
  },
  {
    value: '10',
    label: 'Delaware'
  },
  {
    value: '11',
    label: 'District of Columbia'
  },
  {
    value: '12',
    label: 'Florida'
  },
  {
    value: '13',
    label: 'Georgia'
  },
  {
    value: '15',
    label: 'Hawaii'
  },

  {
    value: '16',
    label: 'Idaho'
  },
  {
    value: '17',
    label: 'Illinois'
  },
  {
    value: '18',
    label: 'Indiana'
  },

  {
    value: '19',
    label: 'Iowa'
  },
  {
    value: '20',
    label: 'Kansas'
  },
  {
    value: '21',
    label: 'Kentucky'
  },
  {
    value: '22',
    label: 'Louisiana'
  },
  {
    value: '23',
    label: 'Maine'
  },
  {
    value: '24',
    label: 'Maryland'
  },
  {
    value: '25',
    label: 'Massachusetts'
  },

  {
    value: '26',
    label: 'Michigan'
  },
  {
    value: '27',
    label: 'Minnesota'
  },
  {
    value: '28',
    label: 'Mississippi'
  },
  {
    value: '29',
    label: 'Missouri'
  },
  {
    value: '30',
    label: 'Montana'
  },
  {
    value: '31',
    label: 'Nebraska'
  },
  {
    value: '32',
    label: 'Nevada'
  },
  {
    value: '33',
    label: 'New Hampshire'
  },
  {
    value: '34',
    label: 'New Jersey'
  },
  {
    value: '35',
    label: 'New Mexico'
  },
  {
    value: '36',
    label: 'New York'
  },
  {
    value: '37',
    label: 'North Carolina'
  },
  {
    value: '38',
    label: 'North Dakota'
  },
  {
    value: '39',
    label: 'Ohio'
  },
  {
    value: '40',
    label: 'Oklahoma'
  },
  {
    value: '41',
    label: 'Oregon'
  },

  {
    value: '42',
    label: 'Pennsylvania'
  },
  {
    value: '44',
    label: 'Rhode Island'
  },
  {
    value: '45',
    label: 'South Carolina'
  },

  {
    value: '46',
    label: 'South Dakota'
  },

  {
    value: '48',
    label: 'Texas'
  },
  {
    value: '47',
    label: 'Tennessee'
  },
  {
    value: '49',
    label: 'Utah'
  },
  {
    value: '50',
    label: 'Vermont'
  },
  {
    value: '51',
    label: 'Virginia'
  },
  {
    value: '53',
    label: 'Washington'
  },
  {
    value: '54',
    label: 'West Virginia'
  },
  {
    value: '55',
    label: 'Wisconsin'
  },
  {
    value: '56',
    label: 'Wyoming'
  }
];
