import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';
import { color } from '../../../styles';

const SwitchIcon = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1.7rem;
  letter-spacing: 1px;
  font-size: 7px;
  color: white;
  font-weight: 700;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

const OnSwitchIcon = styled(SwitchIcon)`
  padding-left: 0.75rem;
`;

const OffSwitchIcon = styled(SwitchIcon)`
  padding-left: 0.12rem;
`;

const { sapphireD20, sapphireD40 } = color;

const SliderToggle = ({ ariaLabel, dataCy, handleChange, id, isChecked, isDisabled = false }) => {
  return (
    <StyledSwitch
      aria-label={ariaLabel}
      checked={isChecked}
      checkedIcon={<OnSwitchIcon>ON</OnSwitchIcon>}
      data-cy={dataCy}
      disabled={isDisabled}
      handleDiameter={12}
      height={16}
      id={id}
      onChange={handleChange}
      onColor={sapphireD20}
      offColor={sapphireD40}
      uncheckedIcon={<OffSwitchIcon>OFF</OffSwitchIcon>}
      width={40}
    />
  );
};

SliderToggle.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};

export default SliderToggle;
