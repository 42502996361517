import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading, WidgetBuilderSelect } from 'components';

const StyledLoading = styled(Loading)`
  height: 11.5rem;
`;

const Heading = styled.h3`
  font-weight: 600;
  margin-bottom: 2rem;
`;

const AlumniWidgetBuilderCampus = props => {
  const { configurations, campusesData, setConfigurations } = props;
  return (
    <StyledLoading hasText={false} loaded={!!campusesData}>
      <Heading>Campus</Heading>
      {!!campusesData && (
        <WidgetBuilderSelect
          configurations={configurations}
          id="aw-campus"
          label="Select Campus"
          optionsList={[{ label: 'none', value: '' }, ...campusesData]}
          selectionLabel="selectedCampus"
          setConfigurations={setConfigurations}
          {...props}
        />
      )}
    </StyledLoading>
  );
};

AlumniWidgetBuilderCampus.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderCampus;
