import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WidgetBuilderSelect, WidgetBuilderToggle } from 'components';

const Heading = styled.h3`
  font-weight: bold;
`;

const Checkbox = styled.input`
  margin: 0 1rem;
`;

const AlumniWidgetBuilderOverviewData = ({ configurations, setConfigurations }) => {
  return (
    <Fragment>
      <Heading>Overview Data</Heading>
      <p>Select the data points to be displayed in the widget</p>
      <WidgetBuilderToggle
        id="overview-salary"
        label="Estimated Salary"
        handleChange={() => {
          let newHasOverview = true;
          const { hasSalary, hasEmployedInField, hasSubsequentAttainment } = configurations;
          if (hasSalary && !hasEmployedInField && !hasSubsequentAttainment) {
            newHasOverview = false;
          }
          setConfigurations({
            ...configurations,
            hasOverview: newHasOverview,
            hasSalary: !hasSalary
          });
        }}
        isChecked={configurations.hasSalary}
      />
      <label>
        <Checkbox
          type="checkbox"
          id="include-salary-range"
          value={configurations.hasSalaryRange}
          data-cy="include-salary-range-checkbox"
          disabled={!configurations.hasSalary}
          checked={configurations.hasSalaryRange}
          onChange={() => {
            setConfigurations({
              ...configurations,
              hasSalaryRange: !configurations.hasSalaryRange
            });
          }}
        />
        Include salary range
      </label>
      <WidgetBuilderToggle
        id="overview-employed-in-field"
        label="% Employed In Field"
        handleChange={() => {
          let newHasOverview = true;
          const { hasSalary, hasEmployedInField, hasSubsequentAttainment } = configurations;
          if (hasEmployedInField && !hasSalary && !hasSubsequentAttainment) {
            newHasOverview = false;
          }
          setConfigurations({
            ...configurations,
            hasEmployedInField: !hasEmployedInField,
            hasOverview: newHasOverview
          });
        }}
        isChecked={configurations.hasEmployedInField}
      />
      <WidgetBuilderToggle
        id="subsequent-attainment-level"
        label="Subsequent Attainment Level"
        handleChange={() => {
          let newHasOverview = true;
          const { hasSalary, hasEmployedInField, hasSubsequentAttainment } = configurations;
          if (hasSubsequentAttainment && !hasEmployedInField && !hasSalary) {
            newHasOverview = false;
          }

          setConfigurations({
            ...configurations,
            hasOverview: newHasOverview,
            hasSubsequentAttainment: !hasSubsequentAttainment
          });
        }}
        isChecked={configurations.hasSubsequentAttainment}
      />
      {configurations.hasSubsequentAttainment && (
        <WidgetBuilderSelect
          id="subsequent-attainment-level"
          label="Select degree level"
          optionsList={[
            { label: 'Bachelors', value: 'bachelors' },
            { label: 'Graduate', value: 'graduate' }
          ]}
          selectionLabel="subsequentAttainmentLevel"
          configurations={configurations}
          setConfigurations={setConfigurations}
        />
      )}
    </Fragment>
  );
};

AlumniWidgetBuilderOverviewData.propTypes = {
  configurations: PropTypes.object.isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default AlumniWidgetBuilderOverviewData;
